import React, { useRef, useState } from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { parsePhoneNumber } from '../utils/libphonenumber-js.custom';

const BecomeAPartnerPage = () => {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		phone: '',
		businessName: '',
		zipCode: ''
	});
	const [showThankYou, setShowThankYou] = useState(false);
	const [phoneInputError, setPhoneInputError] = useState('');
	const phoneInput = useRef(null);

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// Assuming phoneInput.current.value holds the phone number input by the user
		const phoneNumberString = phoneInput.current.value;
		const phoneNumber = parsePhoneNumber(phoneNumberString, 'US');

		// Check if the phone number is valid and specifically a US number
		if (phoneNumber && phoneNumber.isValid() && phoneNumber.country === 'US') {
			setPhoneInputError('is-good');
		} else {
			setPhoneInputError('is-warning');
			return;
		}

		const zapierWebhookUrl = 'https://hooks.zapier.com/hooks/catch/18182216/3xa5y1o/';

		fetch(zapierWebhookUrl, {
			method: 'POST',
			mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(formData)
		})
			.then(() => {
				setShowThankYou(true);
				setFormData({
					name: '',
					email: '',
					phone: '',
					businessName: '',
					zipCode: ''
				});
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

	return (
		<Layout>
			<Seo 
			    title="Become a hear.com partner audiologist | hear.com" 
			    description="Join hear.com's team of top audiologists in the US! Partnering with us means increasing your new customer base. Elevate your practice today!" 
			 />
			<div className="o-wrapper">
				<div className="o-row-1">
					<h1 className="c-partner__title">Become a hear.com Partner Audiologist and grow your business with new customers!</h1>
				</div>
				<div className="o-row-2">
					<p className="c-partner__subtitle">Join the #1 network of hearing care professionals in the US.</p>
				</div>
				<div className="o-row-4">
					<img
						className="o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/partners/partners-hero.jpg"
						alt="man trying hearing aids"
					/>
				</div>
				<div className="o-row-4">
					<div className="c-partner-side-to-side">
						<div className="c-partners-left-side">
							<ul className="c-partners-usps">
								<li>Make use of the power of the internet</li>
								<li>Keep your independence while leaving your competition behind</li>
								<li>Become a member of the #1 hearing care network for FREE</li>
							</ul>
						</div>
						<div className="c-partners-right-side">
							<div className="c-form-container">
								<div className="o-row-4">
									<p className="c-form-container__title">Request more information here:</p>
								</div>
								<form className="c-form-partner" onSubmit={handleSubmit}>
									<div className="c-form-container__label">
										<label className="c-label-partner" htmlFor="name">
											Name *
										</label>
										<input
											className="c-input-partner"
											type="text"
											id="name"
											name="name"
											required
											value={formData.name}
											onChange={handleChange}
										/>
									</div>
									<div className="c-form-container__label">
										<label className="c-label-partner" htmlFor="email">
											E-Mail *
										</label>
										<input
											className="c-input-partner"
											type="email"
											id="email"
											name="email"
											required
											value={formData.email}
											onChange={handleChange}
										/>
									</div>
									<div className="c-form-container__label">
										<label className="c-label-partner" htmlFor="phone">
											Phone Number *
										</label>
										<input
											className={`c-input-partner ${phoneInputError ? phoneInputError : ''}`}
											ref={phoneInput}
											type="tel"
											id="phone"
											name="phone"
											required
											value={formData.phone}
											onChange={handleChange}
										/>
									</div>
									<div className="c-form-container__label">
										<label className="c-label-partner" htmlFor="businessName">
											Business Name *
										</label>
										<input
											className="c-input-partner"
											type="text"
											id="businessName"
											name="businessName"
											required
											value={formData.businessName}
											onChange={handleChange}
										/>
									</div>
									<div className="c-form-container__label">
										<label className="c-label-partner" htmlFor="zipCode">
											Zip Code *
										</label>
										<input
											className="c-input-partner"
											type="text"
											id="zipCode"
											name="zipCode"
											required
											value={formData.zipCode}
											onChange={handleChange}
										/>
									</div>
									<button type="submit" className="submit-btn">
										Send
									</button>
								</form>
								{showThankYou && (
									<div className="c-partners-overlay">
										<div className="c-partners-popup">
											<button className="c-partners-close-btn" onClick={() => setShowThankYou(false)}>
												&times;
											</button>
											<h2>Thank You!</h2>
											<p>
												One of our Partner Managers will be in
												<br /> touch with you as soon as possible.
											</p>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="o-row-4">
					<section className="c-partners-section c-partners-section--gray">
						<div className="c-partners-section__content">
							<div className="o-row-2">
								<h2 className="c-partners-section__content-title">Your customers are online. Are you?</h2>
							</div>
							<p className="c-partners-section__content-copy">
								The hearing aid industry continues to experience exponential growth. Two factors contributing to this
								growth are: (1) big box stores like Costco have entered the market, and (2) people are actively
								searching for hearing aids online. In order for your practice to be even more successful, you need to
								keep up with the big players in the industry and take full advantage of the power of the Internet by joining our network of audiologists.
							</p>
						</div>
						<div className="c-partners-section__img">
							<img
								className="c-image-width-short o-fluid-img"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/partners/thmas.jpg"
								alt="thomas"
							/>
						</div>
					</section>
				</div>
				<div className="o-row-4">
					<section className="c-partners-section">
						<div className="c-partners-section__img">
							<img
								className="c-image-width-long o-fluid-img"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/partners/us-map.png"
								alt="us map"
							/>
						</div>
						<div className="c-partners-section__content">
							<p className="c-partners-section__content-copy">
								Our innovative marketing approach attracts over 85,000 visitors to our website daily, making us the
								<strong> biggest online hearing aid provider in the world!</strong> Our marketing strategy allows us to
								unlock the potential of the Internet for independent audiologists and hearing specialists. It’s
								absolutely FREE to <strong>become a hear.com partner audiologist</strong> and be part of one of the fastest growing networks of hearing care providers in
								America.
							</p>
						</div>
					</section>
					<p className="c-partners-section__disc">
						"When you partner with hear.com, you can be sure that you will see a significant increase of qualified
						customers walk through your doors."
					</p>
				</div>
				<div className="o-row-4">
					<section className="c-partners-section c-partners-section--gray">
						<div className="c-partners-section__content">
							<div className="o-row-2">
								<h2 className="c-partners-section__content-title">What makes hear.com customers unique?</h2>
							</div>
							<p className="c-partners-section__content-copy">
								Our experienced marketing team of 50+ professionals focuses on targeting people who are searching the
								web for hearing aids. This typically draws in younger, first-time users who would rather get help online
								before going to your office. Once we have a customer on the phone, one of our 100+ hearing experts
								pre-qualifies the client and ensures that by our comprehensive consultation every customer is
								well-informed before visiting your practice.
							</p>
						</div>
						<div className="c-partners-section__img">
							<img
								className="c-image-width-short o-fluid-img"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/partners/team.jpg"
								alt="team"
							/>
						</div>
					</section>
				</div>
				<div className="o-row-4">
					<section className="c-partners-section">
						<div className="c-partners-section__img">
							<img
								className="c-image-width-long o-fluid-img"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/partners/man-and-map.jpg"
								alt="man and hearing aids on map of the us"
							/>
						</div>
						<div className="c-partners-section__content">
							<p className="c-partners-section__content-title">To summarize, our customers are:</p>
							<ul className="c-partners-usps-summarize">
								<li>Younger and usually first-time users</li>
								<li>Eager to find a hearing loss solution</li>
								<li>Well-informed</li>
								<li>Ready for their appointment with you</li>
							</ul>
						</div>
					</section>
				</div>
				<section className="c-partners-section c-partners-section--gray">
					<div className="c-partners-section__content">
						<div className="o-row-2">
							<p className="c-partners-section__content-copy">
								"We are constantly adding new partners to our existing base of 1,600 practices. Since we reserve
								specific regions exclusively for each and every partner, spots are limited. Sign up here to check for
								availability in your area."
							</p>
						</div>
						<h2 className="c-partners-section__content-subtitle">Join the #1 hearing care network in the U.S. today!</h2>
					</div>
					<div className="c-partners-section__author">
						<div>
							<img
								className="c-partners-section__author-img o-fluid-img"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/partners/zach-harada.png"
								alt="Zach Harada - Partner Acquisition at hear.com"
							/>
						</div>
						<p className="c-partners-section__author-content">
							<strong>Zach Harada</strong>
							<br /> Business Development | Partner Acquisition
							<br /> Phone: +1 (786) 574-6204
							<br /> Email: <a href="mailto:zach.harada@hear.com">zach.harada@hear.com</a>
						</p>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default BecomeAPartnerPage;
